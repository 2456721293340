<template>
  <b-row md="12">
    <b-col md="12">
      <b-card title="Liste des utilisateurs" no-body>
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="float-right mb-1" onclick="history.back()">
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" size="sm" class="float-right mb-1" float="right" v-b-modal.reabonnement>
                <feather-icon icon="ArrowLeftCircleIcon" />
                Souscrire a un abonnement
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">
            <!-- sorting  -->
            <b-form-group label="Trier" label-size="sm" label-align-sm="left" label-cols-sm="2" label-for="sortBySelect" class=" mb-md-0">
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions">
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group label="Filtrer" label-cols-sm="2" label-align-sm="left" label-size="sm" label-for="filterInput" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Rechercher" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>
        <b-table striped hover responsive small :busy="loader" class="position-relative" :per-page="perPage" :current-page="currentPage" :items="structure.structure.abonnements" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner style="width: 2rem; height: 2rem;" variant="primary" label="Large Spinner" type="grow" class="mt-2" />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(role)="data">
            <b-badge pill :variant="`${resolveUserRoleVariant(data.item.role)}`">
              {{ data.item.role }}
            </b-badge>
          </template>
          <template #cell(status)="data">
            <b-badge pill :variant="`${resolveUserStatusVariant(data.item.status)}`" class="text-capitalize">
              {{ data.item.status }}
            </b-badge>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item v-b-modal.validation>
                <feather-icon icon="UserCheckIcon" class="text-success" />
                <span class="align-middle ml-50 text-success">Resilier</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group label="Par Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect" class="text-nowrap mb-md-0 mr-1">
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination v-model="currentPage" :total-rows="structure.structure.abonnements.length" :per-page="perPage" first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0 pagination-center">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>
    <b-modal id="reabonnement" ref="my-modal" cancel-title="Non" ok-title="Oui" title="Validation de compte" modal-class="modal-success" ok-variant="success" button-size="sm" cancel-variant="outline-secondary" @ok="handleOk">
      <b-col v-for="abonnement in abonnements" :key="abonnement.libelle" md="6">
        <b-card no-body class="business-card">
          <b-card-header class="p-1">
            <b-card-title style="text-align: center;">
              {{ abonnement.libelle }}
            </b-card-title>
          </b-card-header>

          <b-card-body>
            <b-card-text>{{ abonnement.description }}</b-card-text>
            <h6 class="mb-75">
              {{ abonnement.prix }}
            </h6>
            <!-- button -->
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block @click="open(abonnement.prix, abonnement.id, structure.structure.id)">
              Souscrire
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import useStructures from '@/services/structure/structureService'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BFormInput, BForm,
  BFormTextarea, BSpinner, BBadge, BCardTitle, BCardText, BAvatar, BCardHeader,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useAbonnements from '@/services/admin/abonnementService'
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from 'kkiapay'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BCardHeader,
    BPagination,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormTextarea,
    BSpinner,
    BBadge,
    BCardTitle,
    BCardText,
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      abonnements, getAbonnements, reabonnement, errors, abonnementSuccess, abonnement, updateAbonnement, loader,
    } = useAbonnements()
    const {
      getStructure, structure, structureProcess,
    } = useStructures()
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'libelle', label: 'Libellé', sortable: true },
      { key: 'description', label: 'Description', sortable: true },
      { key: 'nombre', label: 'Nombre de CEV souscrits', sortable: true },
      { key: 'created_at', label: 'Date d\'abonnement', sortable: true },
      { key: 'actions' },
    ]
    const form = reactive({
      libelle: '',
      description: '',
      prix: '',
      nombre: '',
    })
    /* eslint-disable global-require */
    onMounted(() => {
      getAbonnements()
      // Set the initial number of items
      const { id } = JSON.parse(store.state.authStore.user)
      getStructure(id)
      console.log(structure)
    })
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    // Reset Form
    const resetForm = () => {
      form.libelle = null
      form.description = null
      form.slug = null
    }

    // Obtenir une abonnement par son slug
    const getAbonnement = async item => {
      form.id = item.id
      form.libelle = item.libelle
      form.description = item.description
      form.prix = item.prix
      form.nombre = item.nombre
    }
    // S'Enregistrer
    const seReabonner = async data => {
      await reabonnement({ ...data })
      const { id } = JSON.parse(store.state.authStore.user)
      getStructure(id)
    }

    // Modifier une abonnement
    const editAbonnement = async () => {
      await updateAbonnement(form).then(() => {
        if (abonnementSuccess.value === true) {
          resetForm()
        }
      })
    }
    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      structureProcess,
      sortBy,
      sortDesc,
      reabonnement,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      fields,
      onFiltered,
      abonnements,
      form,
      structure,
      errors,
      abonnementSuccess,
      required,
      formatter,
      abonnement,
      getAbonnement,
      seReabonner,
      editAbonnement,
      loader,
    }
  },
  data() {
    return { datas: {} }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    addKkiapayListener('success', this.successHandler)
  },
  beforeDestroy() {
    removeKkiapayListener('success', this.successHandler)
  },
  methods: {
    open(amount, abonnement, structure) {
      this.datas = {
        abonnement_id: abonnement,
        structure_id: structure,
      }
      openKkiapayWidget({
        amount,
        api_key: '0160bea0d63d11edb475518c9ef1db3a',
        sandbox: true,
        // phone: '97000000',
      })
    },
    successHandler(response) {
      this.seReabonner(this.datas)
      // this.checkAbonnement(response.transactionId)
      console.log(response)
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.ValidationRules.validate().then(async success => {
        if (success) {
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#toggle-btn')
          })
        }
      })
    },
    handleUpdateOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleUpdateModal()
    },
    handleUpdateModal() {
      // Exit when the form isn't valid
      this.$refs.ValidationRules.validate().then(async success => {
        if (success) {
          // Push the name to submitted names
          await this.editAbonnement()
          if (this.abonnementSuccess) {
            this.$nextTick(() => {
              this.$refs['my-modal1'].toggle('#toggle-btn')
            })
          }
        }
      })
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
